/*
 * 업무구분: 고객
 * 화 면 명: MSPCM180M
 * 화면설명: VIP서비스 신청
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="VIP서비스 신청" :show-title="true" type="subpage" actionType="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM181P()" @on-scroll-bottom="fn_LoadingData()"
   :topButton="true" :topButtonBottom="topButtonBottom">
    <template #frame-header-fixed>
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData180M !== {}" @click="fn_OpenMSPCM181P">
            <span class="txt">{{pOrgData180M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData180M !== {}" @click="fn_OpenMSPCM181P">
            <span class="txt">{{pOrgData180M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData180M !== {}" @click="fn_OpenMSPCM181P">
            <span class="txt">{{pOrgData180M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData180M !== {}" @click="fn_OpenMSPCM181P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData180M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM181P">
            <span class="tit">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[1] !== ''" @click="fn_OpenMSPCM181P">
            <span class="tit">신청현황</span>
            <span class="txt">{{lv_ReturnStr[1]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[2] !== ''" @click="fn_OpenMSPCM181P">
            <span class="tit">VIP등급</span>
            <span class="txt">{{lv_ReturnStr[2]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <!--  검색영역 : 고객명 검색영역 //-->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" size="small" :disabled="checkCount < 1"/>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{totCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{ checkCount }}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container v-if="iCVipCustSrvcAplInfoVO.length > 0" direction="column" alignV="start" class="msp-cm-wrap mb90"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="iCVipCustSrvcAplInfoVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItem(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <!-- 고객명 -->
                <div class="list-item__contents__title">
                  <!-- 고객카드 없음 240129 추가 -->
                  <span class="name" style="display: flex; padding-right: 18px;">
                    <span class="txtSkip" @click.stop="fn_OpenCustInfoBs(item)">
                      {{ item.custNm }}
                    </span>
                    <span :class="!item.chnlCustId ? 'need-cs-card' : null" />
                  </span>
                  <!-- 고객카드 없음 240129 추가 -->
                  <!-- 등급 -->
                  <mo-badge class="badge-sample-badge lightblue sm crTy-blue3" :text="item.vipGrdNm" shape="status">{{item.vipGrdNm}}</mo-badge> 
                  <!-- <mo-badge class="badge-sample-badge lightgreen sm crTy-blue3" text="디어앤드" shape="status" v-if="item.data10 === '2'">{{item.data3}}</mo-badge> 
                  <mo-badge class="badge-sample-badge lightyellow sm crTy-blue3" text="디어앤드" shape="status" v-if="item.data10 === '3'">{{item.data3}}</mo-badge>  -->
                </div>
                <!-- 컨설턴트 -->
                <div class="list-item__contents__info" v-if="isMngr">
                  <span><span class="crTy-bk7 fs14rem mr10">컨설턴트</span><span class="crTy-bk1">{{item.cnsltNm}}</span></span>
                </div>
                <!-- 생년월일, SVIP No -->
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.rrnFrnoNo)}}</span>
                  <em></em>
                  <span class="crTy-bk1"><span class="crTy-bk7 fs14rem mr10">SVIP No</span>{{item.vipId}}</span>
                </div>
                <!-- 신청현황, 신청일자 -->
                <div class="list-item__contents__info" v-if="item.odrStuCd === '9'">
                  <span class="fwb crTy-orange2">{{item.odrStuCdNm}}</span>
                </div>
                <div class="list-item__contents__info" v-else-if="item.odrStuCd === '1'">
                  <span class="fwb crTy-blue2">{{item.odrStuCdNm}}</span>
                </div>
                <div class="list-item__contents__info" v-else>
                  <span class="crTy-bk1">{{item.odrStuCdNm}}</span>
                  <em></em>
                  <span><span class="crTy-bk7 fs14rem mr10">신청일자</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.vipSrvcAplYmd)}}</span></span>
                </div>
                <!-- 신청구분(VIP서비스신청구분코드명), 신청물품명 -->
                <div class="list-item__contents__info ellipsis-resposive-txtSkip2" v-if="!$bizUtil.isEmpty(item.vipSrvcAplYmd)">
                  <span class="fex-rw">
                    <span class="crTy-bk1 white-space-nowrap" @click.stop="fn_SetMSPCM184P(item)">{{item.prdtScNm}}</span>
                    <em class="mt6"></em>
                    <span class="crTy-bk1 underline txtSkip2" @click.stop="fn_SetMSPCM184P(item)">{{item.prdtNm}}</span>
                  </span>
                </div>
                <div class="list-item__contents__info" v-if="(item.vipDcdGrdCd === '61' || item.vipDcdGrdCd === '62') && !$bizUtil.isEmpty(item.vipSrvcAplYmd)">
                  <span><span class="crTy-bk7 fs14rem mr10">방문결과</span>
                  <span class="crTy-bk1" v-if="item.strRsltCd === '방문' || item.strRsltCd === '미방문'">{{item.strRsltCd}}</span>
                  <span class="crTy-red2" v-else>{{item.strRsltCd}}</span></span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- 서비스 신청 bottomSheet -->
      <mo-bottom-sheet ref="bottomSheetApl" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet only-btn no-background positionUp' : 'ns-bottom-sheet only-btn no-background'" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="small" v-if="lv_SheetFlag === 'apl'" @click="fn_BtnMSPCM183P" class="ns-btn-round fs17rem bd-radius-6 blue">서비스 신청</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="small" v-else-if="lv_SheetFlag === 'inpt'" @click="fn_OpenMSPCM186P" class="ns-btn-round fs17rem bd-radius-6 blue">결과 입력</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="small" v-else @click="fn_OpenMSPCM186P" class="ns-btn-round fs17rem bd-radius-6 blue">결과 수정</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </ur-box-container>

    <!-- 서비스 준비중 영역  -->
    <ur-box-container v-else-if="lv_SrvcReady" alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>서비스 준비중입니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <!-- NoData 영역  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <!-- VIP서비스 안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetCnfrm" class="ns-bottom-sheet ns-style3">
      <template v-slot:title>VIP서비스 안내</template>
      <div>
        <ul class="cancel-ment bullet-type-6">
          <li>검진/선물 문의 1544-7837<br>  (*운영시간 9:00~17:00)</li>
          <li>장제/결혼화환 문의 1661-5058</li>
          <li>VIP 서비스 이용 및 고객 본인확인을 위해 SVIP넘버를 확인해주세요</li>
        </ul>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 고객카드생성 안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetCust" class="ns-bottom-sheet ns-style3">
      <div class="customer-info-txt txt-center">
        <p class="ment">해당 고객은 고객카드가 없습니다.<br>고객 카드 생성 후 서비스 신청 가능합니다.</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_InitBottomSheet" name="취소">취소</mo-button>
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AgrClick" name="확인">고객카드 생성</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- Toast -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
    <div v-if="positionUp && iCVipCustSrvcAplInfoVO.length > 0" class="ns-height120"></div>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPCM181P from '@/ui/cm/MSPCM181P' // 조회조건팝업
import MSPCM182P from '@/ui/cm/MSPCM182P' // VIP 선물 선택
import MSPCM183P from '@/ui/cm/MSPCM183P' // 선택 전 안내
import MSPCM184P from '@/ui/cm/MSPCM184P' // 선택한 서비스 확인
import MSPCM186P from '@/ui/cm/MSPCM186P' // 방문결과 입력
import MSPCM120P from '@/ui/cm/MSPCM120P' // 메모
import MSPCM128P from '@/ui/cm/MSPCM128P' // 수신고객 목록
import MSPAP302D from '@/ui/ap/MSPAP302D' // 일정 추가
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM180M',
  // 현재 화면 ID
  screenId: 'MSPCM180M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM181P,
    MSPCM182P,
    MSPCM183P,
    MSPCM184P,
    MSPCM186P,
    MSPCM120P,
    MSPCM128P,
    MSPAP302D,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      isSearched: false,  // 검색 실행 여부
      isInit: false, // 검색 초기화 여부
      isMngr: false, // 지점장 여부
      isFirst: true, // 초회조회여부
      toastMsg: '', // 문자발송 toast 문구
      pOrgData180M: {}, // 최근 조직 조회 데이터

      // 공통 코드
      vipDcdGrdCdList: [],
      odrStuCdList: [],
      vipSrvcAplScCdList: [],
      vipMdemDstrcCdList: [],
      vipCllgMdemDstrtCdList: [],
      vipMdemHopMntCd: [],
      vipMdemHsplCd: [],
      vipMdemHsplTelCdList: [],
    
      // 제어 변수
      isOneOrMoreCheck: false, // 전체선택
      checkDisabled: false, // 체크박스 비활성화 여부
      positionUp: false, // 고객동의 바텀시트 강제 여부
      isCustCardExist: true, // 채널아이디 여부

      // 팝업 연동
      popup181: {}, // MSPCM181P VIP서비스신청 고객 검색
      popup182: {}, // MSPCM182P VIP 선물 선택
      popup183: {}, // MSPCM183P 선택 전 안내
      popup184: {}, // MSPCM184P 선택한 서비스 확인
      popup186: {}, // MSPCM186P 방문결과 입력
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      //선택한 서비스 확인(184p) 변수
      lv_CnsltInfo: {},
      lv_CustInfo: {},
      lv_GiftInfo: {},
      lv_HospitalInfo: [],  // 검진처 리스트(코드)
      //방문결과 입력(186p) 변수
      lv_SelectedItem: {},
      
      // 데이터 변수
      nowDate: this.$bizUtil.getDateToString(new Date(), ''),
      iCVipCustSrvcAplInfoVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 조회 서비스호출 Obj
        hofOrgNo: '', // 사업부조직번호
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '',
        custNm: '',       // 고객명
        vipDcdGrdCd: '',  // VIP등급
        odrStuCd: '',     // 신청현황
        vipSrvcAplScCd: '', // VIP서비스신청구분코드
        hopDlvYmd: '' // 희망배달일
      },
      totCnt: 0, // 누적 총 조회건수
      lv_SheetFlag: '', // 바텀시트 버튼 변경

      lv_SrvcReady: false, // 서비스 미운영 기간

      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
      stndKeyVal: 'start',
      pageRowCnt: '50', // 페이지 카운트
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.iCVipCustSrvcAplInfoVO.filter(item => item.checked)
    },
    checkCount () {
      return this.iCVipCustSrvcAplInfoVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData180M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.iCVipCustSrvcAplInfoVO.length && this.iCVipCustSrvcAplInfoVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 공통코드 조회
    this.fn_CommCdList()

    /**
     * 로컬, 검증계 테스트 가능
     * 운영계는 2024-02-26일 전까지 "서비스 준비중"으로 막아놓음
     */
    // if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'stage') {
    //   this.fn_CommCdList()
    // } else {
    //   let today = '';
    //   this.$commonUtil.getServerTime()
    //     .then(res => {
    //       today = moment(res).format('YYYYMMDD');
    //       const openDate = '20240226'
    //       // 2024-02-26 오픈
    //       if (today < openDate) {
    //         this.lv_SrvcReady = true
    //       } else {
    //         // 공통코드 조회
    //         this.fn_CommCdList()
    //       }
    //     });
    // }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    this.fn_InitBottomSheet()
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.isSearched = false // 검색이력
          this.isInit = true
          this.isFirst = true
          this.fn_InitBottomSheet() // 바텀시트
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CommCdList
     * 설명       : 공통코드 조회
     ******************************************************************************/
    fn_CommCdList() {
      let lv_Vm = this

      window.vue.getInstance('vue').$commonUtil.selListMassSliCommCd(['VIP_DCD_GRD_CD', 'ODR_STU_CD', 'VIP_SRVC_APL_SC_CD', 'VIP_MDEM_HSPL_CD', 'VIP_MDEM_DSTRT_CD', 'VIP_CLLG_MDEM_DSTRT_CD', 'VIP_MDEM_HOP_MNT_CD'])
        .then(function () {
          let codeStore = lv_Vm.getStore('code')

          lv_Vm.vipDcdGrdCdList = codeStore.getters.getState.get('VIP_DCD_GRD_CD')
          lv_Vm.odrStuCdList = codeStore.getters.getState.get('ODR_STU_CD')
          lv_Vm.vipSrvcAplScCdList = codeStore.getters.getState.get('VIP_SRVC_APL_SC_CD')
          lv_Vm.vipMdemDstrcCdList = codeStore.getters.getState.get('VIP_MDEM_DSTRT_CD')
          lv_Vm.vipCllgMdemDstrtCdList = codeStore.getters.getState.get('VIP_CLLG_MDEM_DSTRT_CD')
          lv_Vm.vipMdemHopMntCd = codeStore.getters.getState.get('VIP_MDEM_HOP_MNT_CD')
          lv_Vm.vipMdemHsplCd = codeStore.getters.getState.get('VIP_MDEM_HSPL_CD')
        })

      lv_Vm.$commonUtil.selListEACommCd('ZA_VIP_MDEM_HSPL_TEL_CD').then(function (response) {
        lv_Vm.vipMdemHsplTelCdList = lv_Vm.getStore('code').getters.getState.get('ZA_VIP_MDEM_HSPL_TEL_CD')
      })

      // VIP서비스신청 리스트 조회
      lv_Vm.fn_Submit()
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.iCVipCustSrvcAplInfoVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSet() // 조회 파라미터 세팅

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      if (this.isFirst || this.isInit) {
        this.pOrgData180M = {}  // 상세조회조건
        this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
        this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
        this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
        this.lv_inputParam.hofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호
        this.lv_inputParam.custNm = ''
        this.lv_inputParam.vipDcdGrdCd = ''
        this.lv_inputParam.odrStuCd = ''
        this.lv_inputParam.vipSrvcAplScCd = ''
        this.lv_inputParam.hopDlvYmd = ''
        if (this.isMngr) {
          // 지점장일 경우 최초 접속 시 해당 지점건 전체 조회
          this.lv_inputParam.cnsltNo = '0'
          return
        }
      }

      if (!_.isEmpty(this.pOrgData180M) && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData180M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(VIP서비스신청 목록 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM56S1'// VIP서비스신청 리스트 조회
      const trnstId = 'txTSSCM48S8'// VIP서비스신청 리스트 조회
      const auth = 'S'
      const lv_Vm = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU116') {
            let responseList = response.body.iCVipCustSrvcAplInfoVO

            if (responseList !== null) {
              for (let i = 0; i < responseList.length; i++) {
                // 리스트 체크변수 적용
                responseList[i].checked = false
                if ( responseList[i].hopDlvYmd.trim() !== '' ) {
                  responseList[i].hopDlvYmd = responseList[i].hopDlvYmd.substring(0, 4) + '-' + responseList[i].hopDlvYmd.substring(4, 7)
                }

                // 상품구분명 세팅
                /*
                * 선물 / 기타서비스 / 건강검진 구분값이 없으므로 하드코딩함
                * NOBLE: ['04', '05', '06', '10', '11', '12'],
                * DEAR: ['07', '08', '09', '10', '11', '12'],
                */
                if (!lv_Vm.$bizUtil.isEmpty(responseList[i].vipSrvcAplScCd)) {
                  let svcCd = responseList[i].vipSrvcAplScCd
                  if (svcCd === '04' || svcCd === '05' || svcCd === '06' || svcCd === '07' || svcCd === '08' || svcCd === '09') {
                    responseList[i].prdtScNm = '선물'
                  } else if (svcCd === '11' || svcCd === '12') {
                    responseList[i].prdtScNm = '기타서비스'
                  } else {
                    responseList[i].prdtScNm = '건강검진'
                  }
                }

                // 방문결과 세팅
                if (!lv_Vm.$bizUtil.isEmpty(responseList[i].vipCustSrvcRsltCd)) {
                  if (['a', 'b', 'c', 'd', 'e', 'f'].indexOf(responseList[i].vipCustSrvcRsltCd) > -1) {
                    responseList[i].strRsltCd = '방문'
                  } else {
                    responseList[i].strRsltCd = '미방문'
                  }
                } else {
                  responseList[i].strRsltCd = '미입력'
                }
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.iCVipCustSrvcAplInfoVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.iCVipCustSrvcAplInfoVO = responseList
              }

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              if (lv_Vm.lv_inputParam.custNm.trim() !== '') {
                let regex = new RegExp(`^${lv_Vm.lv_inputParam.custNm}`)
                lv_Vm.iCVipCustSrvcAplInfoVO = lv_Vm.iCVipCustSrvcAplInfoVO.filter(e => regex.test(e.custNm))
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.iCVipCustSrvcAplInfoVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })

      if (lv_Vm.isFirst) {
        lv_Vm.$nextTick(() => {
          // VIP서비스 안내 바텀시트 호출
          lv_Vm.$refs.bottomSheetCnfrm.open()
        })
        lv_Vm.isFirst = false
      }
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      // 더보기 시 전체체크 되어있으면 전체체크 해제
      if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
        this.isOneOrMoreCheck = false
      }

      if (!this.isLoading) {
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')
        
        this.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.iCVipCustSrvcAplInfoVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.iCVipCustSrvcAplInfoVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.iCVipCustSrvcAplInfoVO[n].checked = true
            } else {
              lv_Vm.iCVipCustSrvcAplInfoVO[n].checked = false
            }
          }
  
          // 체크 시 BottomSheet 컨트롤
          if (lv_Vm.checkCount > 0) {
            // 단건에 대해 표시하는 버튼 비표시 처리
            lv_Vm.positionUp = false
            lv_Vm.$refs.bottomSheetApl.close()
          } else {
            lv_Vm.fn_InitBottomSheet()
          }
          this.fn_CheckSelectedItem()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItem
     * 설명       : 체크 핸들러
     ******************************************************************************/
    fn_CheckSelectedItem (item) {
      let lv_Vm = this

      // 모달 호출
      // 고객 체크 시 BottomSheet
      this.$cmUtil.setSelectedCustList(this, this.selectItems, this.pOrgData180M)

      // 서비스신청은 단건, 신청가능 상태의 고객에 대해서만 가능
      if (this.selectItems.length === 1 && this.selectItems[0].odrStuCd === '1') {
        this.lv_SheetFlag = 'apl'
        this.positionUp = true
        this.$refs.bottomSheetApl.open()
        return
      } else {
        this.positionUp = false
        this.$refs.bottomSheetApl.close()
      }
      // 신청일 데이터가 있을 경우, 방문결과 입력 버튼 표시
      if (this.selectItems.length === 1) {
        if ((this.selectItems[0].vipDcdGrdCd === '61' || this.selectItems[0].vipDcdGrdCd === '62') && !this.$bizUtil.isEmpty(this.selectItems[0].vipSrvcAplYmd)) {
          if (this.selectItems[0].strRsltCd === '미입력') {
            this.lv_SelectedItem = this.selectItems[0]
            this.lv_SheetFlag = 'inpt'
            this.positionUp = true
            this.$refs.bottomSheetApl.open()
          } else if ((this.selectItems[0].strRsltCd === '방문' || this.selectItems[0].strRsltCd === '미방문')) {
            this.lv_SelectedItem = this.selectItems[0]
            this.lv_SheetFlag = 'updtr'
            this.positionUp = true
            this.$refs.bottomSheetApl.open()
          }
        }
      } else {
        this.positionUp = false
        this.$refs.bottomSheetApl.close()
      }

      // if (!item.checked && this.selectItems.length === 0) this.fn_InitBottomSheet()
      if (this.selectItems.length === 0) this.fn_InitBottomSheet()
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetApl !== undefined) {
          lv_Vm.positionUp = false
          lv_Vm.$refs.bottomSheetApl.close()
        }
        if (lv_Vm.$refs.bottomSheetCnfrm !== undefined) {
          lv_Vm.$refs.bottomSheetCnfrm.close()
        }
        if (lv_Vm.$refs.bottomSheetCust !== undefined) {
          lv_Vm.$refs.bottomSheetCust.close()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_SetMSPCM184P
     * 설명       : 신청 완료건의 선택 서비스 확인 시 필요한 데이터 세팅
     ******************************************************************************/
    async fn_SetMSPCM184P (item) {
      let lv_Vm = this
      
      // 고객정보
      this.lv_CustInfo = item
      // 기선택 선물 정보
      this.lv_GiftInfo = {
        cdEngNm: 'VIP_SRVC_APL_SC_CD',
        cdVal: item.vipSrvcAplScCd,
        cdValAbbrNm: item.prdtNm,
        cdValNm: item.prdtNm,
        key: item.vipSrvcAplScCd,
        label: item.prdtNm,
        uppCdEngNm: ' ',
        uppCdVal: ' '
      }
      // 컨설턴트 정보
      if (this.$bizUtil.isEmpty(this.lv_CnsltInfo.hdofAddr)) {
        let pParam = {cnsltNo: this.lv_CustInfo.cnsltNo}
        let trnstId = 'txTSSCM56S3'
        await this.post(this, pParam, trnstId, 'S')
          .then(function (response) {
            if (response !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU116') {
              let cnsltAddrInfo = response.body
              let orgAddr01 = cnsltAddrInfo.addr + ' ' + cnsltAddrInfo.bldgNoEncr + ' ' // 서울 중구 서소문로 21
              let orgAddr02 = cnsltAddrInfo.dtladEncr + ' ' + cnsltAddrInfo.orgNm + ' ' + cnsltAddrInfo.adtnAddr // 31층 삼성생명 신용산지점 신용산지점(순화동)
              lv_Vm.lv_CnsltInfo.hdofAddr = orgAddr01 + orgAddr02
              lv_Vm.lv_CnsltInfo.frnoPstcd = cnsltAddrInfo.frnoPstcd
              lv_Vm.lv_CnsltInfo.bknoPstcd = cnsltAddrInfo.bknoPstcd
              lv_Vm.lv_CnsltInfo.cnsltNo = cnsltAddrInfo.cnsltNo
              lv_Vm.lv_CnsltInfo.orgNo = cnsltAddrInfo.orgNo
            } else {
              lv_Vm.getStore('toast').dispatch('ADD', '오류가 발생했습니다. 다시 시도해주십시오')
            }
          })
      }
      // 병원 정보
      this.lv_HospitalInfo = this.getStore('code').getters.getState.get('VIP_MDEM_HSPL_CD')

      // 선택 서비스 확인 팝업 오픈
      await this.fn_OpenMSPCM184P()
    },

    /******************************************************************************
     * Function명: fn_OpenMSPCM181P
     * 설명: 고객명 검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM181P () {
      let lv_Vm = this
      
      if (this.lv_SrvcReady) {
        let alertObj = {
          msg: '서비스 준비중입니다.',
          closeBtn: true,
          preventTouchClose: false
        }
        this.$cmUtil.fn_OpenAlert(lv_Vm, alertObj)
        return
      }

      this.popup181 = this.$bottomModal.open(MSPCM181P, {
        properties: {
          pIsMngr: this.isMngr,
          pOrgData: this.pOrgData180M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
          pSearchCustNm: this.lv_inputParam.custNm, // 고객명
          pOdrStuCd: this.lv_inputParam.odrStuCd,  // 신청현황
          pVipDcdGrdCd: this.lv_inputParam.vipDcdGrdCd  // VIP등급
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup181)
            lv_Vm.fn_InitBottomSheet()

            // 마지막 조직 조회 데이터 셋팅
            lv_Vm.pOrgData180M = pData.rtnData.pOrgData
            if (lv_Vm.isMngr) {
              lv_Vm.lv_inputParam.hofOrgNo = lv_Vm.pOrgData180M.hofOrg.key
              lv_Vm.lv_inputParam.dofOrgNo = lv_Vm.pOrgData180M.dofOrg.key
              lv_Vm.lv_inputParam.fofOrgNo = lv_Vm.pOrgData180M.fofOrg.key
            }
            // 검색 실행 여부
            lv_Vm.isSearched = true
            // 검색 초기화 여부
            lv_Vm.isInit = pData.isInit
            if (lv_Vm.isInit) lv_Vm.isSearched = false
            // 조회조건 텍스트 세팅
            lv_Vm.lv_ReturnStr = pData.srchOpt

            // 나머지 데이터 세팅
            lv_Vm.lv_inputParam.custNm = pData.rtnData.lv_SearchCustNm
            lv_Vm.lv_inputParam.odrStuCd = pData.rtnData.lv_OdrStuCd === '999'? '': pData.rtnData.lv_OdrStuCd
            lv_Vm.lv_inputParam.vipDcdGrdCd = pData.rtnData.lv_VipDcdGrdCd === '999'? '': pData.rtnData.lv_VipDcdGrdCd

            // VIP서비스신청 리스트 조회
            lv_Vm.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM182P
     * 설명       : VIP 선물 선택 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM182P () {
      let lv_Vm = this

      this.popup182 = this.$bottomModal.open(MSPCM182P, {
        properties: {
          pCustInfo: this.lv_CustInfo, // 선택한 고객 정보
          pGiftInfo: this.lv_GiftInfo, // 선택한 선물 정보
        },
        listeners: {
          reselect: async (pData) => {
            // 모달 닫기
            await lv_Vm.$bottomModal.close(this.popup182)

            // 이전 화면에서 넘어온 파라미터 세팅
            lv_Vm.lv_HospitalInfo = _.cloneDeep(pData.pHospitalInfo)
            lv_Vm.lv_CustInfo = _.cloneDeep(pData.pCustInfo)
            lv_Vm.lv_GiftInfo = _.cloneDeep(pData.pGiftInfo)
            lv_Vm.lv_CnsltInfo = _.cloneDeep(pData.pCnsltInfo)

            // 선택 서비스 확인 팝업 오픈
            await lv_Vm.fn_OpenMSPCM184P(true)
          },
          onPopupClose: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup182)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_BtnMSPCM183P
     * 설명       : 선택 전 안내 팝업 오픈 전 유효성 체크
     ******************************************************************************/
    fn_BtnMSPCM183P () {
      if (this.$bizUtil.isEmpty(this.selectItems[0].chnlCustId)) {
        // 고객카드생성 바텀시트 오픈
        this.$refs.bottomSheetCust.open()
      } else {
        // 선택 전 안내 팝업 호출
        this.fn_OpenMSPCM183P()
      }
    },

    /******************************************************************************
     * Function명 : fn_AgrClick
     * 설명       : 고객동의 연동
     ******************************************************************************/
    fn_AgrClick () {
      this.$router.push({name: 'MSPCM050M', params: {custNm: this.selectItems[0].custNm, srnId: this.$options.screenId, aprvType: '01'}})
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM183P
     * 설명: 선택 전 안내 팝업 호출
     *********************************************************/
    fn_OpenMSPCM183P () {
      this.popup183 = this.$bottomModal.open(MSPCM183P, {
        properties: {
          pCustInfo: this.selectItems[0],
        },
        listeners: {
          afterSave: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup183)
            this.fn_InitBottomSheet()

            if (pData.flag === 'success') {
              this.getStore('toast').dispatch('ADD', pData.msg)
              // VIP서비스신청 리스트 재조회
              this.fn_Submit()
            } else {
              this.getStore('toast').dispatch('ADD', '작동 중 오류가 발생하였습니다') // as-is msg
            }
          },
          onPopupClose: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup183)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM184P
     * 설명       : 선택 서비스 확인 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM184P (reselect) {
      this.popup184 = this.$bottomModal.open(MSPCM184P, {
        properties: {
          pHospitalInfo: this.lv_HospitalInfo, // 검진처 리스트
          pGiftInfo: this.lv_GiftInfo,  // 기선택선물정보
          pCustInfo: this.lv_CustInfo,  // 고객정보
          pCnsltInfo: this.lv_CnsltInfo, // 컨설턴트정보
          pIsReselect: reselect? reselect: false
        },
        listeners: {
          // 신청서비스 삭제 및 재선택에 대한 콜백
          afterUpdat: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup184)
            this.fn_InitBottomSheet()

            if (pData.flag === 'success') {
              if (!this.$bizUtil.isEmpty(pData.msg)) {
                this.getStore('toast').dispatch('ADD', pData.msg)
              }
              // VIP서비스신청 리스트 재조회
              this.fn_Submit()
            } else {
              this.getStore('toast').dispatch('ADD', '작동 중 오류가 발생하였습니다') // as-is msg
            }
          },
          // 재선택을 위한 콜백(180 > 184 > 182 > 184)
          reselect: async (pData) => {
            // 모달 닫기
            await this.$bottomModal.close(this.popup184)

            // VIP 선물 선택 팝업 오픈
            await this.fn_OpenMSPCM182P(pData.pCustInfo, pData.pGiftInfo)
          },
          onPopupClose: () => {
            // 모달 닫기
            this.$bottomModal.close(this.popup184)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM186P
     * 설명       : 방문결과 입력 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM186P () {
      this.popup186 = this.$bottomModal.open(MSPCM186P, {
        properties: {
          pCustInfo: this.lv_SelectedItem, // 선택한 고객 정보
        },
        listeners: {
          submit: () => {
            // 모달 닫기
            this.$bottomModal.close(this.popup186)
            this.fn_InitBottomSheet()

            // VIP서비스신청 리스트 재조회
            this.fn_Submit()
          },
          onPopupClose: () => {
            // 모달 닫기
            this.$bottomModal.close(this.popup186)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}
      
      if(this.pOrgData180M.cnslt === undefined || this.pOrgData180M.cnslt === null || this.pOrgData180M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData180M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM180M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.isSearched = false // 검색이력
                this.isInit = true
                this.isFirst = true
                this.fn_InitBottomSheet() // 바텀시트

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
