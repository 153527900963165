/*
 * 업무구분: 고객
 * 화 면 명: MSPCM183P
 * 화면설명: 선택 전 안내
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="선택 전 안내" :show-title="true" type="popup">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container direction="column" alignV="start" class="guide-wrap pb80">
        <p class="pt30">건강검진 / 선물 / 기타서비스 중 택1입니다.</p>
        <p class="pt20">[건강검진] 계약자 본인만 이용가능(양도불가)하며, 별도의 검진권은 없습니다.</p>
        <p class="">시스템 신청 익일 고객님이 해당병원에 전화하시어 검진 세부 일정 확정하셔야 예약이 완료됩니다.</p>
        <ul class="guide-list-type-1 crTy-orange2">
          <li>병원 및 검진 내역에 따라 추가 비용이 발생할 수 있습니다. (삼성서울병원 기본 검진시에도 고객부담 발생) <div>또한, 등급에 상관없이 검진 외 프로그램 수진시 추가 개인 비용부담이 발생합니다.</div></li>
          <li>건강검진 항목은 각 검진처로 문의 부탁드립니다.</li>
          <li>디어고객님께서 종합병원 검진 신청시, 추가 20만원 부담이 발생합니다.</li>
        </ul>
        <p class="pt20">[선물] 신청하신 선물은 지점(대리점)으로 발송되며, VIP담당컨설턴트가 직접 고객님께 전달하셔야 합니다.</p>
        <p class="pt20">[기타서비스]</p>
        <ul class="guide-list-type-1 crTy-orange2">
          <li>컨시어지: 희망하는 서비스 시스템 입력 -> 익일 FC/고객이 일정조율을 위해서 전용 콜센터에 연락.</li>
          <li>서비스 신청 후 변경, 취소 불가하고, 등급별 제공 프로그램 이외 추가 진행은 개인 비용 부담이 발생합니다.</li>
        </ul>
      </ur-box-container>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPCM182P">확인</mo-button>
        </div>
      </ur-box-container>  
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
  * 공통 라이브러리 INCLUDE 영역	                                                   *
  ***********************************************************************************/
import MSPCM182P from '@/ui/cm/MSPCM182P' // VIP 선물 선택

export default {
  /***********************************************************************************
    * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
  name: "MSPCM183P",
  screenId: "MSPCM183P",
  components: {
    MSPCM182P
  },
  props: {
    pCustInfo: Object,
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
    * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
  created() {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    // 이전 화면에서 넘어온 파라미터 세팅
    this.lv_CustInfo = _.cloneDeep(this.$props.pCustInfo)
  },
  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
    * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
  data() {
    return {
      // 팝업 변수
      popup182: {}, // VIP 선물 선택 팝업 변수

      // 화면 변수
      lv_CustInfo: {},  // 선택한 고객 정보
    };
  },
  /***********************************************************************************
    * Computed 함수 정의 영역                                                         *
    ***********************************************************************************/
  computed: {
  },
  /***********************************************************************************
    * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 취소 버튼 선택 이벤트
     ******************************************************************************/
    fn_Cancel () {
      this.$emit('onPopupClose')
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM182P
     * 설명       : VIP 선물 선택 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM182P () {
      this.popup182 = this.$bottomModal.open(MSPCM182P, {
        properties: {
          pCustInfo: this.lv_CustInfo // 선택한 고객 정보
        },
        listeners: {
          afterSave: () => {
            // 모달 닫기
            this.$bottomModal.close(this.popup182)

            let rtnData = {flag: 'success', msg: '신청이 완료되었습니다'}
            this.$emit('afterSave', rtnData)
          },
          onPopupClose: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup182)
          }
        }
      })
    },
  }
};
</script>
<style scoped>
</style>