/*
 * 업무구분: 고객
 * 화 면 명: MSPCM182P
 * 화면설명: VIP 선물 선택
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="VIP 선물 선택" :show-title="true" type="popup">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignH="start" componentid="" direction="row" class="ns-radio-list2 type-1">
        <div class="pl24 pr24 pt30 pb12 fs16rem fwm" v-if="lv_VipDcdGrdCd === '61'">노블 (택1)</div>
        <div class="pl24 pr24 pt30 pb12 fs16rem fwm" v-else-if="lv_VipDcdGrdCd === '62'">디어 (택1)</div>
        <mo-list-item class="bd-T-Ty1"> 
          <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand w100" :class="{'open' : lv_OpenTab === 'gift'}" btn-area-class="fexTy3 "> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name fwb">선물</span>
                </div>
              </div> 
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="list-item__contents">
                <mo-list :list-data="lv_VipGift_A" class="bd-T-Ty1">
                  <template #list-item="{item, index}">
                    <mo-list-item :class="{'on' : item.cdVal === lv_RadioSelect}">
                      <div class="image-list-area mr20">
                        <mo-radio v-model="lv_RadioSelect" :value="item.cdVal" class="bgwhite mr10"/>
                        <img v-bind:src='fn_getImgUrl(item.cdVal)'>
                      </div>
                      <div class="list-item__contents">
                        <div class="list-item__contents__info mt0">
                          <span class="fs14rem hauto">{{'선물' + String(index + 1)}}</span>
                        </div>
                        <div class="list-item__contents__info mt0">
                          <span class="hauto fwm crTy-bk1 fs16rem">{{item.cdValNm}}</span>
                        </div>
                        <div class="list-item__contents__info mt0">
                          <span class="hauto fwm crTy-bk1 fs16rem"><mo-button color="normal" shape="hyperlink" size="large" class="pl0 crTy-blue4 min75" @click="fn_OpenMSPCM187P(item)">상세보기</mo-button></span>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </div>
            </template>
          </msp-expand>
        </mo-list-item> 
        <mo-list-item> 
          <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand w100" :class="{'open' : lv_OpenTab === 'subs'}" btn-area-class="fexTy3 "> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name fwb">기타서비스</span>
                </div>
              </div> 
            </template>
            <template #btn>
                <span class="vip fs14rem crTy-orange2 fwm dsInline">VIP외 양도불가 </span>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="list-item__contents">
                <mo-list :list-data="lv_VipGift_B" class="bd-T-Ty1">
                  <template #list-item="{item}">
                    <mo-list-item :class="{'on' : item.cdVal === lv_RadioSelect}">
                      <div class="image-list-area mr20">
                        <mo-radio v-model="lv_RadioSelect" :value="item.cdVal" class="bgwhite mr10"/>
                        <img v-bind:src='fn_getImgUrl(item.cdVal)'>
                      </div>
                      <div class="list-item__contents">
                        <div class="list-item__contents__info mt0">
                          <span class="hauto fwm crTy-bk1 fs16rem">{{item.cdValNm}}</span>
                        </div>
                        <div class="list-item__contents__info mt0">
                          <span class="hauto fwm crTy-bk1 fs16rem"><mo-button color="normal" shape="hyperlink" size="large" class="pl0 crTy-blue4 min75" @click="fn_OpenMSPCM187P(item)">상세보기</mo-button></span>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </div>
            </template>
          </msp-expand>
        </mo-list-item> 
        <mo-list-item> 
          <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand w100" :class="{'open' : lv_OpenTab === 'mdem'}" btn-area-class="fexTy3 "> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name fwb">건강검진</span>
                </div>
              </div> 
            </template>
            <template #btn>
                <span class="vip fs14rem crTy-orange2 fwm dsInline">VIP외 양도불가 </span>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="list-item__contents">
                <mo-list :list-data="lv_VipGift" class="bd-T-Ty1">
                  <template #list-item="{item}">
                    <mo-list-item :class="{'on' : item.cdVal === lv_RadioSelect}" v-show="item.cdVal === '10'">
                      <mo-radio v-model="lv_RadioSelect" :value="item.cdVal" class="bgwhite mr10"/>
                      <div class="list-item__contents">
                        <div class="list-item__contents__info mt3">
                          <span class="hauto fwm crTy-bk1 fs16rem">당사-병원 간 협약검진프로그램</span>
                        </div>
                        <div class="list-item__contents__info mt10">
                          <div class="">
                            <span class="hauto fs14rem">VIP고객이 희망 병원/검진월을 담당 컨설턴트에게 신청 → 사랑On/유파트너 컨설턴트가 입력
                             → 컨설턴트 입력 다음날부터 <b class="fwn crTy-orange2">반드시 고객이 직접 병원에 전화</b>하여 검진일자/추가항목 결정
                            검진예약 취소 또는 변경은 콜센터 1544-7837 접수 (단, 취소변경 일정은 병원별 상이)
                            </span>
                          </div>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </div>
            </template>
          </msp-expand>
        </mo-list-item> 
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="lv_RadioSelect === ''" @click="fn_GetCnsltInfo">다음</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
    <div class="ns-height70"></div>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPCM184P from '@/ui/cm/MSPCM184P' // 선택 서비스 확인 팝업
import MSPCM187P from '@/ui/cm/MSPCM187P' // 선물 상세보기 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM182P",
  screenId: "MSPCM182P",
  components: {
    MSPCM184P,
    MSPCM187P
  },
  props: {
    pCustInfo: Object,
    pGiftInfo: Object
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  computed: {
  },
  created() {
    // 이전 화면에서 넘어온 파라미터 세팅
    this.lv_CustInfo = _.cloneDeep(this.$props.pCustInfo)
    this.lv_DefaultGift = _.cloneDeep(this.$props.pGiftInfo)
    if (!_.isEmpty(this.lv_DefaultGift)) this.isReselect = true

    this.lv_VipDcdGrdCd = this.lv_CustInfo.vipDcdGrdCd // VIP등급 코드

    // VIP선물 리스트 호출
    this.fn_getGftList(this.lv_VipDcdGrdCd)
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 팝업 변수
      popup184: {}, // 선택 서비스 확인 팝업 변수
      popup187: {}, // 선물 상세보기 팝업 변수

      // 데이터 변수
      lv_HospitalInfo: [],  // 검진처 리스트
      lv_CustInfo: {},  // 선택한 고객 정보
      lv_VipDcdGrdCd: '', // VIP등급 코드
      lv_VipGift: [], // VIP서비스 선물 리스트
      lv_VipGift_A: [], // 선물
      lv_VipGift_B: [], // 기타서비스
      lv_DefaultGift: {}, // 선택되었던 선물 정보
      lv_RadioSelect: '', // 리스트 선택 항목(cdVal로 선택)
      lv_CnsltInfo: {},  // 컨설턴트 정보
      lv_OpenTab: '', // 열어둘 탭

      // 제어 변수
      isReselect: false,  // 재선택 여부
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 취소 버튼 선택 이벤트
     ******************************************************************************/
    fn_Cancel () {
      this.$emit('onPopupClose')
    },

    /******************************************************************************
     * Function명 : fn_getGftList
     * 설명       : VIP등급에 따른 선물 매칭
     ******************************************************************************/
    fn_getGftList (vipDcdGrdCd) {
      let lv_Vm = this
      let gftIdxList = {
        // PLATINUM: ['04', '05', '06', '10', '11'],
        // PLATINUM2: ['07', '08', '09', '10', '11'],
        // GOLD: ['01', '02', '03', '10', '11']
        NOBLE: ['01', '02', '03', '04', '10', '11', '12'],
        DEAR: ['05', '06', '08', '09', '10', '11', '12'],
        DREAM: []
        // Dream: ['01', '02', '03', '10']
        // HOS_PLATINUM: ['a', 'b', 'd', 'f', 'g', 'i', 'j', 'm'],
        // HOS_RES: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k']
      }

      let retType = ''
      // if (vipDcdGrdCd === '11') {
      //   retType = 'PLATINUM'
      // } else if (vipDcdGrdCd === '12') {
      //   retType = 'PLATINUM2'
      // } else if (vipDcdGrdCd === '20') {
      //   retType = 'GOLD'
      // }
      if (vipDcdGrdCd === '61') {
        retType = 'NOBLE'
      } else if (vipDcdGrdCd === '62') {
        retType = 'DEAR'
      } else if (vipDcdGrdCd === '63') {
        retType = 'DREAM'
      }
      let giftNoList = this.getStore('code').getters.getState.get('VIP_SRVC_APL_SC_CD')
      let vipMdemHsplCd = this.getStore('code').getters.getState.get('VIP_MDEM_HSPL_CD')
      let ret = []
      if (this.lv_VipGift.length === 0) {
        let l = gftIdxList[retType].length
        for (let i = 0; i < l; i++) {
          let idx = gftIdxList[retType][i]
          for (let j = 0; j < giftNoList.length; j++) {
            if (giftNoList[j].cdVal === idx) {
              ret.push(giftNoList[j])
              break
            }
          }
        }
        this.lv_VipGift = ret
      }

      // 병원
      // let hospital_entire = []
      // let hotspital_segment = []
      // if (lv_Vm.commCdList.VIP_HOSPITAL_SC_CD['PLATINUM'].length === 0) {
      //   for (let i = 10; i < giftNoList.length; i++) {
      //     if (giftNoList[i].cdVal === '11') continue
      //     hospital_entire.push(giftNoList[i])
      //     // if (giftNoList[i].cdValNm.indexOf('[종합]') > -1) let_platinum.push(giftNoList[i])
      //   }

      //   hotspital_segment = hospital_entire.filter(item => item.cdValNm.indexOf('[종합]') > -1)
      //   lv_Vm.commCdList.VIP_HOSPITAL_SC_CD.PLATINUM = hotspital_segment
      //   lv_Vm.commCdList.VIP_HOSPITAL_SC_CD.REST = hospital_entire
      // }
      lv_Vm.lv_HospitalInfo = vipMdemHsplCd

      // 기존에 선택되었던 선물 정보 세팅
      let hasSelected = this.lv_DefaultGift
      if (!_.isEmpty(hasSelected)) {
        if (!_.isEmpty(this.lv_VipGift.filter(item => item.cdVal === hasSelected.cdVal)[0])) {
          this.lv_RadioSelect = this.lv_VipGift.filter(item => item.cdVal === hasSelected.cdVal)[0].cdVal
        } else {
          // 건강검진
          this.lv_RadioSelect = '10'
        }
        
        // 진입 시 열어둘 탭 세팅
        if (['01', '02', '03', '04', '05', '06', '08', '09'].indexOf(this.lv_RadioSelect) > -1) {
          this.lv_OpenTab = 'gift'
        } else if (['11', '12'].indexOf(this.lv_RadioSelect) > -1) {
          this.lv_OpenTab = 'subs'
        } else if (this.lv_RadioSelect === '10') {
          this.lv_OpenTab = 'mdem'
        }
      }

      /*
      * 선물 / 기타서비스 / 건강검진 구분값이 없으므로 하드코딩함
      * NOBLE: ['01', '02', '03', '04', '10', '11', '12'],
      * DEAR: ['05', '06', '08', '09', '10', '11', '12'],
      */
      if (vipDcdGrdCd === '61') { // 노블앤드
        // 선물
        lv_Vm.lv_VipGift_A = lv_Vm.lv_VipGift.filter(e => e.cdVal === '01' || e.cdVal === '02' || e.cdVal === '03' || e.cdVal === '04')
        // 기타서비스
        lv_Vm.lv_VipGift_B = lv_Vm.lv_VipGift.filter(e => e.cdVal === '11' || e.cdVal === '12')
      } else if (vipDcdGrdCd === '62') { // 디어앤드
        // 선물
        lv_Vm.lv_VipGift_A = lv_Vm.lv_VipGift.filter(e => e.cdVal === '05' || e.cdVal === '06' || e.cdVal === '08' || e.cdVal === '09')
        // 기타서비스
        lv_Vm.lv_VipGift_B = lv_Vm.lv_VipGift.filter(e => e.cdVal === '11' || e.cdVal === '12')
      }
    },

    /******************************************************************************
     * Function명 : fn_getImgUrl
     * 설명       : 화면에 매핑할 이미지 경로 세팅
     ******************************************************************************/
    fn_getImgUrl: function (cdVal) {
      let imgString = 'gift_' + cdVal
      return require('@/assets/img/customer/vip/' + imgString + '.png')
    },

    /******************************************************************************
     * Function명 : fn_GetCnsltInfo
     * 설명       : 컨설턴트 정보 세팅
     ******************************************************************************/
    async fn_GetCnsltInfo() {
      let lv_Vm = this

      if (this.$bizUtil.isEmpty(lv_Vm.lv_CnsltInfo.hdofAddr)) {
        let pParam = {cnsltNo: lv_Vm.lv_CustInfo.cnsltNo}
        let trnstId = 'txTSSCM56S3'
        await lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
          .then(function (response) {
            if (response !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU116') {
              let cnsltAddrInfo = response.body
              let orgAddr01 = cnsltAddrInfo.addr + ' ' + cnsltAddrInfo.bldgNoEncr + ' ' // 서울 중구 서소문로 21
              let orgAddr02 = cnsltAddrInfo.dtladEncr + ' ' + cnsltAddrInfo.orgNm + ' ' + cnsltAddrInfo.adtnAddr // 31층 삼성생명 신용산지점 신용산지점(순화동)
              lv_Vm.lv_CnsltInfo.hdofAddr = orgAddr01 + orgAddr02
              lv_Vm.lv_CnsltInfo.frnoPstcd = cnsltAddrInfo.frnoPstcd
              lv_Vm.lv_CnsltInfo.bknoPstcd = cnsltAddrInfo.bknoPstcd
              lv_Vm.lv_CnsltInfo.cnsltNo = cnsltAddrInfo.cnsltNo
              lv_Vm.lv_CnsltInfo.orgNo = cnsltAddrInfo.orgNo
            } else {
              lv_Vm.getStore('toast').dispatch('ADD', '오류가 발생했습니다. 다시 시도해주십시오')
            }
        })
      }

      // 신청완료 건에 대한 재선택 완료(180-184-182)
      if (this.isReselect) {
        let selectedItem = this.lv_VipGift.filter(e => e.cdVal === this.lv_RadioSelect)
        let rtnData = {
          pHospitalInfo: this.lv_HospitalInfo, // 검진처 리스트
          pGiftInfo: selectedItem[0],
          pCustInfo: this.lv_CustInfo, // VIP등급 코드
          pCnsltInfo: this.lv_CnsltInfo // 컨설턴트정보
        }
        this.$emit('reselect', rtnData)
      } else {
        // 선택 서비스 확인 팝업 오픈(180-183-182-184)
        await this.fn_OpenMSPCM184P()
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM184P
     * 설명       : 선택 서비스 확인 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM184P () {
      // 선택 아이템 정보 세팅
      let selectedItem = this.lv_VipGift.filter(e => e.cdVal === this.lv_RadioSelect)
      this.popup184 = this.$bottomModal.open(MSPCM184P, {
        properties: {
          pHospitalInfo: this.lv_HospitalInfo, // 검진처 리스트
          pGiftInfo: selectedItem[0], // 선택한 상품정보
          pCustInfo: this.lv_CustInfo,  // 고객정보
          pCnsltInfo: this.lv_CnsltInfo // 컨설턴트정보
        },
        listeners: {
          afterSave: () => {
            // 모달 닫기
            this.$bottomModal.close(this.popup184)

            this.$emit('afterSave')
          },
          onPopupClose: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup184)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM187P
     * 설명       : 선물 상세보기 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM187P (item) {
      this.popup187 = this.$bottomModal.open(MSPCM187P, {
        properties: {
          pCdVal: item.cdVal
        },
        listeners: {
          onPopupClose: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup187)
          }
        }
      })
    },
  }
};
</script>
<style scoped>
</style>